<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
         <ion-list>
            <ion-item class="ion-no-padding" @click="setOpen(true)">
				Выйти из аккаунта
				<ion-alert
					:is-open="isOpenRef"
					header="Выход"
					sub-header="Требуется подтверждение"
					message="Вы уверены что хотите выйти из аккаунта?"
					css-class="my-custom-class"
					:buttons="buttons"
					@didDismiss="setOpen(false)"
					>
				</ion-alert>
            </ion-item>
			<ion-item class="ion-no-padding">
				BeOn.Fun v0.5 (20211231)
            </ion-item>
          </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}
ion-item{
    --inner-padding-end: 0.5%;
    --padding-start:0.5%;
	padding-bottom: 20px;
}
.post__commcount {
	text-align: right;
}
</style>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList, IonAlert } from '@ionic/vue';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export default {
	name: 'App/Settings',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		IonAlert
	},
	methods: {
	},
  setup() {
    const route = useRoute();
	const router = useRouter();
    const folder = "Настройки";
	const { token, updateToken, updateUser } = useDataService();
	
	const isOpenRef = ref(false);
    const setOpen = (state: boolean) => isOpenRef.value = state;
	
	const doLogout = function(){
		axios.get('https://beon.fun/api/v1/logout',{
		headers: {
			Authorization: 'Bearer ' + token.value,
		}})
		.then((response) => {
			if(response.data.status === 'ok'){
				updateToken(null);
				updateUser(null);
				Storage.set({
					key: 'user',
					value: ''
				});
				Storage.set({
					key: 'token',
					value: ''
				});
				router.replace('/login');
			}
		});
	};
	
    const buttons = [
		{
			text: 'Подтвердить',
			handler: () => {
				doLogout()
			},
		},
		{
			text: 'Отмена',
			role: 'cancel',
			cssClass: 'secondary',
		},
	];
	
    return { folder, buttons, isOpenRef, setOpen }
  }
}
</script>