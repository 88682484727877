
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList, IonAlert } from '@ionic/vue';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export default {
	name: 'App/Settings',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		IonAlert
	},
	methods: {
	},
  setup() {
    const route = useRoute();
	const router = useRouter();
    const folder = "Настройки";
	const { token, updateToken, updateUser } = useDataService();
	
	const isOpenRef = ref(false);
    const setOpen = (state: boolean) => isOpenRef.value = state;
	
	const doLogout = function(){
		axios.get('https://beon.fun/api/v1/logout',{
		headers: {
			Authorization: 'Bearer ' + token.value,
		}})
		.then((response) => {
			if(response.data.status === 'ok'){
				updateToken(null);
				updateUser(null);
				Storage.set({
					key: 'user',
					value: ''
				});
				Storage.set({
					key: 'token',
					value: ''
				});
				router.replace('/login');
			}
		});
	};
	
    const buttons = [
		{
			text: 'Подтвердить',
			handler: () => {
				doLogout()
			},
		},
		{
			text: 'Отмена',
			role: 'cancel',
			cssClass: 'secondary',
		},
	];
	
    return { folder, buttons, isOpenRef, setOpen }
  }
}
